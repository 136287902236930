$sansSerif: sans-serif

//TODO: remove these or set it to our scheme
html,
button,
input,
select,
textarea
  color: $black

body
  font-family: $sansSerif
  -webkit-font-smoothing: antialiased
  color: $black
  font-size: 16px
  line-height: 1.3
