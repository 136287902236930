@import '../../assets/styles/palette.sass'
@import '../../assets/styles/consts'

.login-wrapper
  .block-page.form-background
    @media (min-width: $loginMinDesktopWidth)
      min-width: 500px
      .form-content
        width: 100%
  .major-button
    @media (max-width: $loginMinDesktopWidth)
      width: 100%
      min-width: 100%
.background-hexagon
  @media (max-width: $loginMinDesktopWidth)
    display: none
.background-children-container
  @media (max-width: $loginMinDesktopWidth)
    text-align: left
.meta-form-error
  @media (max-width: $loginMinDesktopWidth)
    min-width: 100%
    max-width: 100%
    width: 100%
.meta-form-success
  @media (max-width: $loginMinDesktopWidth)
    width: 100%
    min-width: 100%
.password-input-field
  @media (max-width: $loginMinDesktopWidth)
    width: 100%
.text-input-field
  @media (max-width: $loginMinDesktopWidth)
    width: 100%
    input
      width: 100%
    .input-error
      max-width: 100%
.meta-networks-logo
  @media (max-width: $loginMinDesktopWidth)
    height: 23px
    width: 230px
    margin: 72px 0 30px 40px
  @media (max-height: $minDesktopHeight)
    margin-bottom: 20px
  @media (max-width: $loginZeroDesktopWidth)
    margin: 30px 0 30px 40px

.login-wrapper .form-background
  @media (max-width: $loginMinDesktopWidth)
    height: calc(100% - 80px)
    width: 100%
    border: none
    box-shadow: none
    .idp-container
      min-height: 90px
    .hidden
      display: none

.ios, .android
  .login-wrapper
    .meta-form-error
      width: 100%
