@import '../../../../../assets/styles/palette.sass'
@import '../../../../../assets/styles/mixins'
@import '../../../../../assets/styles/consts'

.set-phone-form
  color: $darker-blue-grey
  max-width: 400px
  margin: 40px auto 0
  font-family: Meta-Regular


  .phone-input-container
    text-align: left
    margin-bottom: 48px
    @media (max-width: $loginMinDesktopWidth)
      width: 300px
      margin: 0 auto 48px

    .react-tel-input
      margin-top: 12px

      input
        width: 100%

      .country-list
        text-align: left

  .major-button
    margin-top: 87px

  .have-account-container
    margin-top: 30px

  .meta-form-error
    margin-top: 10px

  .meta-page-paragraph
    display: inline
