@import '../../assets/styles/palette.sass'
@import '../../assets/styles/mixins'
@import '../../assets/styles/consts'

.bottom-link-container
  text-align: center
  .secondary-button.disabled
    color: $greyOpacity3
    &:hover
      color: $greyOpacity3
      cursor: auto

.bottom-link-container
  margin-top: 10px

  .link-container
    font-size: 14px
    display: inline-block
    cursor: pointer

  .bottom-link-text
    color: $second-darkest
    font-size: 14px
    font-family: Meta-Regular
