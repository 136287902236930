@import '../../../../../assets/styles/palette.sass'
@import '../../../../../assets/styles/mixins'
@import '../../../../../assets/styles/consts'

.select-mfa-container
  .meta-page-paragraph
    margin: 20px auto 0
    text-align: left
    max-width: 400px

.select-mfa-form
  margin: 37px auto 0
  color: $bluish-gray
  text-align: left

  @media (max-width: $loginMinDesktopWidth)
    width: 100%

  .factors-title
    margin-top: 43px
    color: $very-dark-blue

  .factors-container
    margin-top: 30px

  .factor-container
    width: 100%
    display: table
    outline: none

    &:hover
      cursor: pointer

      .factor-title
        color: $light-blue-800
        z-index: 9

      .icon
        .st0, path
          fill: $light-blue-800

  .icon-cell
    width: 40px
    display: table-cell
    vertical-align: middle

    .icon
      height: 20px
      width: 20px
      .st0, path
        fill: $bluish-gray

  .factor-title
    display: table-cell
    padding: 6px 0 6px 10px
    border-bottom: 1px solid $another-shade-of-white
