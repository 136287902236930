@import '../../assets/styles/palette.sass'
@import '../../assets/styles/consts'

.app .background-container
    background: $app-background
.background-container
    width: 100%
    background-repeat: no-repeat
    display: table
    background-size: cover
.background-hexagon
    position: absolute
    top: 0
    background-image: url("../../assets/images/hexagon-bg.svg")
    background-repeat: repeat-x
    mix-blend-mode: screen
    width: 100vw
    height: 480px

.background-children-container
    z-index: 1
    width: 100%
    user-select: none
    position: relative
    display: table-cell
    vertical-align: middle
    text-align: center

.customized-bg-container
    width: 100vw
    height: 100vh
    display: table
    background-size: cover
