@import '../../../../assets/styles/mixins'
@import '../../../../assets/styles/consts'

.reset-password-form
  text-align: center
  .loader-wrapper
    min-height: 56px
  .text-input-field
    min-height: 82px
    .icon-ic-lock
      font-size: 24px
      top: 8px
  .major-button
    margin: 35px auto 0
.reset-password-wrapper
  .meta-page-paragraph
    margin-bottom: 20px
    @media (max-width: $loginMinDesktopWidth)
      width: auto

.app
  .login-wrapper
    .reset-password-form
      .text-input-field
        input
          margin: 30px auto 0
