@import '../../assets/styles/palette.sass'

.password-input-field
  position: relative
  .show-password-icon
    position: absolute
    top: 10px
    right: 5px
    cursor: pointer
    .icon
      width: 23px
      .st0
        fill: $very-dark-blue
    &:focus
      outline: none
  .password-shown .icon .st0
    fill: $darker-blue-grey
  .wrapped-text-input-field
    input
      padding-right: 35px
    .error-icon
      position: absolute
      top: 5px
      right: 30px
