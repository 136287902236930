body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Meta-Regular';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/icons/fonts/Roboto/Roboto-Regular.ttf') format('woff');
}
@font-face {
  font-family: 'Meta-Bold';
  font-weight: 700;
  src: url('./assets/icons/fonts/Roboto/Roboto-Bold.ttf') format('woff');
}
@font-face {
  font-family: 'Meta-Medium';
  src: url('./assets/icons/fonts/Roboto/Roboto-Medium.ttf') format('woff');
}
@font-face {
  font-family: 'Meta-Light';
  src: url('./assets/icons/fonts/Roboto/Roboto-Light.ttf') format('woff');
}
@font-face {
  font-family: 'Meta-SemiBold';
  src: url('./assets/icons/fonts/Roboto/Roboto-Medium.ttf') format('woff');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
