@import '../../assets/styles/palette.sass'
@import '../../assets/styles/mixins'
@import '../../assets/styles/consts'

.password-meter
  margin: 0 auto
  border-bottom: 1px solid $lighter-gray
  box-sizing: border-box
  font-family: Meta-Regular
  font-size: 16px
  text-align: left
  line-height: 1
  position: relative

  @media (max-width: $loginMinDesktopWidth)
    width: 100%

  &.active
    .strength-bar-container
      visibility: visible
      opacity: 1

  .password-input
    width: calc(85% - 28px)
    padding: 14px 0 12px 12px
    border: none
    box-sizing: border-box
    font-size: 16px
    color: #2c2758
    letter-spacing: 1.1px

    &:not(:focus).is-password-invalid
      color: $invalid-red
    &:focus
      outline: none

    +placeholder()
      color: $grey
      font-size: 13px
      letter-spacing: 0

  .strength-bar-container
    width: 100px
    height: 40px
    position: absolute
    right: 0
    top: 0
    visibility: hidden
    opacity: 0
    transition: visibility 0s, opacity 0.5s linear

    .strength-desc
      display: block
      text-align: right
      transition: color 250ms ease-in-out

    .strength-bar
      height: 5px
      margin-top: 8px
      background: $disabled-grey
      transition: width 300ms ease-out

      .strength-bar-meter
        float: right
        display: block
        height: 5px


  .show-password-btn
    display: none
    cursor: pointer
    font-size: 14px
    color: $light-blue-800
    position: absolute
    right: 2px
    top: 52px

  .show-password-btn.visible
    display: block


    &:focus
      outline: none

.is-strength-0
  .strength-desc
    color: $invalid-red
  .strength-bar-meter
    background: $invalid-red
    width: 20%

.is-strength-1
  .strength-desc
    color: $invalid-red
  .strength-bar-meter
    background: $invalid-red
    width: 40%


.is-strength-2
  .strength-desc
    color: $valid-blue
  .strength-bar-meter
    background: $valid-blue
    width: 60%

.is-strength-3
  .strength-desc
    color: $valid-blue
  .strength-bar-meter
    background: $valid-blue
    width: 80%

.is-strength-4
  .strength-desc
    color: $success-green
  .strength-bar-meter
    background: $success-green
    width: calc(100% + 2px)

.password-suggestions-container
  margin: 48px auto

.password-suggestions
  padding: 15px 15px 15px 30px
  font-size: 14px
  background: #faf2cc
  color: #6b6b6b
  text-align: left
  visibility: hidden
  &.visible
    visibility: visible


