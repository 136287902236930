@import '../../assets/styles/palette.sass'
@import '../../assets/styles/mixins'

.secondary-button
  text-align: center
  font-size: 13px
  color: $light-blue-800
  font-family: Meta-Regular
  cursor: pointer
  text-decoration: none
  &:link, &:visited
    color: $meta-link
  &:hover
    color: $visited-meta-link
  &:active
    color: $visited-meta-link
  &:disabled
    color: $another-shade-of-white
  &:focus
    outline: none
