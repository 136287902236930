@import '../../../../../assets/styles/palette.sass'
@import '../../../../../assets/styles/mixins'
@import '../../../../../assets/styles/consts'

.set-authenticator-page
  .meta-page-sub-title
    margin: 0 auto
  .instructions
    max-width: 320px
    margin: 20px auto 0
    color: $bluish-gray
    text-align: left
    list-style-type: decimal
    font-size: 14px
    font-family: Meta-Regular

    @media (max-width: $loginMinDesktopWidth)
      width: 100%

    p
      span
        font-weight: 600

  .cant-scan
    margin-top: 25px

  .set-authenticator-form
    color: $darker-blue-grey
    text-align: center
    margin-top: 20px
    label
      font-size: 16px
      line-height: 22px

    input
      margin-top: 30px

    .meta-button
      margin: 48px auto 0
      .button-container
        width: 210px

      @media (max-width: $mobileMaxWidth)
        font-size: 4vw

    .meta-form-error
      margin-top: 10px

  .set-authenticator-form.manual
    text-align: center
    position: relative

    .secret
      margin-top: 40px
      font-size: 25px
      color: $disabled-text
      text-align: center
      border: none

      @media (max-width: $mobileMaxWidth)
        font-size: 5vw

    .clipboard
      width: 20px
      height: 20px
      position: absolute
      right: -5px
      top: 5px
      background-image: url('../../../../../assets/images/clipboard.png')
      background-size: 100%
      background-repeat: no-repeat
      border: none
      outline: none

      @media (max-width: $mobileMaxWidth)
        font-size: 4vw
