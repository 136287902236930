@import '../../../../assets/styles/palette.sass'
@import '../../../../assets/styles/consts'

.choose-login-method
  text-align: center
  &.form-background
    .form-content
      @media (max-width: $loginMinDesktopWidth)
        padding-left: 0
        padding-right: 0
  .idp-container
    min-height: 120px
    display: flex
    align-items: center
    flex-direction: column
    justify-content: space-evenly
  .separator-text
    display: table
    width: 300px
    font-size: 14px
    overflow: hidden
    margin: 10px auto 20px
    padding: 10px 0
    white-space: nowrap
    text-align: center
    color: $second-darkest
    @media (max-width: $loginMinDesktopWidth)
      margin: 10px auto
    &:before, &:after
      border-top: 1px solid $darker-blue-grey
      content: ''
      display: table-cell
      position: relative
      top: 0.5em
      width: 120px
    &:before
      right: 0
      top: 8px
    &:after
      left: 0
      top: 8px
  .idp-button
    width: 300px
    min-width: auto
    margin: 8px auto
    @media (max-width: $loginMinDesktopWidth)
      width: 100%
      text-align: center
      font-size: 18px
      .idp-button-text-wrapper
        &.short
          margin-left: 41px
      .idp-button-text
        padding-right: 41px
        width: calc(100% - 41px)

