@import '../../../../assets/styles/palette.sass'
@import '../../../../assets/styles/mixins.sass'
@import '../../../../assets/styles/consts'

.verify-pin-popover
  z-index: 1
  background-color: $white
  box-shadow: 0 2px 1px 1px rgba(123,123,123,0.24)
  .popover-header
    padding: 8px 24px
    @media (min-width: $loginMinDesktopWidth)
      text-align: center
    font-size: 16px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    color: $very-dark-blue
    font-family: Meta-Regular

  .popover-body
    table
      color: $darker-blue-grey
      line-height: 34px
      @media (max-width: $loginMinDesktopWidth)
        line-height: 20px
        span
          display: block
          margin: 5px
      table-layout: auto
      width: 100%
      border-collapse: collapse
      tr
        border-bottom: solid
        border-bottom-width: 1px
        border-bottom-color: $another-shade-of-white
        td
          vertical-align: middle
          padding: 0 25px 0 0
          .icon
            height: 20px
            width: 20px
            .st0
              fill: $darker-blue-grey
        .icon-cell
          padding-left: 25px
        &:hover
          color: $light-blue-800
          cursor: pointer
          .icon .st0
            fill: $light-blue-800
        &:active
          background-color: $another-shade-of-white
          color: $light-blue-800
          cursor: pointer
          .icon .st0
            fill: $light-blue-800
