@import '../../../../assets/styles/palette.sass'

.block-page.form-background
  min-height: 350px
  text-align: center
  user-select: text
  padding: 20px 20px 40px
  .form-content
    padding-bottom: 0

  .block-image
    width: 124px

  .reply-box
    display: flex
    flex-direction: row
    justify-content: center
    font-size: 14px
    line-height: 20px
    .contest-email
      color: $second-darkest
      margin-right: 3px
      > a
        color: $light-blue-800

  .proceed-button
    margin-top: 32px
    margin-bottom: 32px
    > a
      text-decoration: auto
      border: 1px solid $borderGreyBlue
      border-radius: 4px
      cursor: pointer
      padding: 6px 18px
      color: $light-blue-800
      font-family: Roboto-Medium
      font-size: 13px

  .meta-page-sub-title
    font-size: 26px
    color: $red2
    margin: 15px auto 35px

  .meta-page-paragraph
    display: block
    text-align: center
    word-break: break-word
    color: $second-darkest
    margin: 0 auto
    margin-bottom: 32px
    font-size: 18px
    line-height: 20px
    > b
      font-family: Meta-Bold

  .meta-page-titles
    &.title
      .meta-page-sub-title
        display: table
        font-family: Meta-Regular
      .meta-page-paragraph
        margin-bottom: 3px
        white-space: pre-wrap
        .acceptable-service
          font-weight: bold
          color: $blue2
    &.instructions
      .meta-page-paragraph
        white-space: pre-wrap
        font-size: 14px
        line-height: 23px
        margin-bottom: 16px

    &.blocked-url
      .meta-page-paragraph
        font-family: Meta-Bold
        white-space: nowrap
        text-overflow: ellipsis
        overflow: hidden
        font-size: 18px
        line-height: 32px
