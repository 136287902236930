@import '../../assets/styles/palette.sass'
@import '../../assets/styles/mixins'

.major-button
  min-width: 300px
  display: inline-block
  margin: 0 auto
  padding: 12px 30px
  border-radius: 4px
  font-family: Meta-SemiBold
  font-size: 19px
  color: $white
  background-color: $light-blue-800
  text-align: center
  white-space: pre-line
  text-decoration: none
  border: none
  @include align-items(center)
  outline: none
  @include justify-content(center)
  user-select: none
  cursor: pointer

  &:hover
    background-color: $light-blue-800
  &:active
    background-color: $light-blue-800
  &:disabled
    background-color: $another-shade-of-white
    border-color: $another-shade-of-white
    cursor: default
    pointer-events: none

  &.hollow-button
    padding: 15px
