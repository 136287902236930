@import '../../assets/styles/palette.sass'
@import '../../assets/styles/mixins'

.meta-form-success
  @include flexbox()
  @include align-items(center)
  @include justify-content(space-between)
  min-width: 400px
  min-height: 45px
  background-color: $green-opac
  .success-icon
    @include flexbox()
    @include align-items(center)
    padding-right: 16px
    img
      width: 24px
      height: 24px
      margin-left: 10px
  .input-success
    color: $turquoise
    font-size: 14px
    font-family: Meta-SemiBold
    letter-spacing: 1px
    padding-left: 18px
