@import '../../../../assets/styles/palette.sass'
@import '../../../../assets/styles/mixins.sass'

.verify-pin-page
  .more-methods
    margin: 30px 0
    @include flexbox()
    @include align-items(center)
    cursor: pointer
    .more-methods-button-wrapper
      margin: auto
      display: table
    .app-icon
      width: 32px
      height: 32px
      display: table-cell
      margin-right: 5px
    .icon
      width: 11px
      height: 11px
      margin-top: -5px
    .st0
      fill: $very-dark-blue
    .drop-down-container
      margin-top: -7px
      display: table-cell
      vertical-align: middle
    &:hover .st0
      fill: $light-blue-800
  .meta-page-paragraph
    white-space: pre-line
    text-align: center
