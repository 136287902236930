$backgroundLightBlue: #4a81ad
$backgroundDarkBlue: #1d3d70
$backgroundWhite: #CFD8DC
$red: #E34F62
$whiteBorder: #F5F6F6
$backgroundWhiteDarker: #e7e2e2
$bannerBlue: #0277BD
$watermelon: #D12443
$very-dark-blue: #2c2758
$lightPink: #d07088
$greenish-gray: #546E7A
$meta-link: #5479FF