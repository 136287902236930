@import '../../../../assets/styles/palette.sass'
@import '../../../../assets/styles/mixins'
@import '../../../../assets/styles/consts'

.forgot-password-wrapper
  .loader-wrapper
    min-height: 56px
  .meta-page-paragraph
    @media (max-width: $loginMinDesktopWidth)
      max-width: 100%


.forgot-password-form
  margin-top: 20px
  @include flexbox()
  @include align-items(center)
  @include flex-direction(column)
.back-to-login
    font-family: Meta-Regular
    text-align: center
    margin-top: 15px
    a
      font-size: 14px
      letter-spacing: 0.7px
      text-transform: uppercase
      text-decoration: none
      &:link, &:visited
        color: $light-blue-800
      &:hover
        color: $lightPink
      &:active
        color: $pink
