@import '../../../../palette.sass'

.error-page
  min-height: 350px
  text-align: center
  user-select: text
  .error-image
    width: 175px
  .meta-page-sub-title
    margin-bottom: 24px
  .meta-page-paragraph, .secondary-button
    display: inline
  .input-error
    color: $watermelon
    font-size: 14px
    font-family: Meta-SemiBold
    letter-spacing: 1px
    margin-bottom: 24px
    overflow-wrap: break-word
    white-space: pre-wrap
  .request-id
    color: $very-dark-blue
    font-size: 14px
    font-family: Meta-SemiBold
    letter-spacing: 1px
    margin-bottom: 24px
    padding-left: 18px
  .request-response
    width: 300px
    padding: 15px
    margin: 15px auto 0
    color: $lightPink
    font-size: 13px
    font-family: Meta-Regular
    overflow: scroll
    -moz-box-shadow: inset 0 0 10px lightgrey
    -webkit-box-shadow: inset 0 0 10px lightgrey
    box-shadow: inset 0 0 10px lightgrey
  .form-content
    .related-url-container
      align-self: center
      display: flex
      flex-direction: row
      justify-content: space-between
      margin-bottom: 10px
      .related-url
        font-size: 14px
        align-self: center
        color: $greenish-gray
        text-align: left
        width: 100%
        overflow-wrap: break-word
        .url-title
          text-align: center
      .meta-button
        padding-left: 5px
        .button-container
          padding-top: 3px
          border: 0
          width: 20px
      .copy-section-container
        position: absolute
        right: 10px
        .url-copied-indication
          font-size: 12px
          position: absolute
          left: 100%
          top: 3px
          padding: 6px

