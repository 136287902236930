@import '../../assets/styles/palette.sass'
@import '../../assets/styles/consts'

.success-page-wrapper
  .button-wrapper
    margin-top: 100px
    width: 100%
    margin-bottom: 20px
  .meta-page-image
    width: 50px
    height: 50px
    margin: 20px auto

  .text-wrapper
    .meta-page-paragraph
      margin-top: 20px
      text-align: center
    .meta-page-sub-title
      margin-top: 20px
      margin-bottom: 0
      font-size: 22px
      text-transform: inherit
      overflow: hidden
      text-overflow: ellipsis
  .secondary-button
    @media (max-width: $loginMinDesktopWidth)
      display: block
      margin: auto !important
  .bottom-link-container
    margin-top: 40px


