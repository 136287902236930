@import '../../assets/styles/palette.sass'
@import '../../assets/styles/mixins'
@import '../../assets/styles/consts'

.meta-form-error
  @include flexbox()
  @include align-items(center)
  width: 100%
  padding: 0 15px
  background-color: $watermelon-opac

  .error-icon
    @include flexbox()
    @include align-items(center)
    img
      width: 16px
      height: 16px
      margin-left: 10px
  .input-error
    color: $red2
    font-size: 14px
    font-family: Meta-Regular
    padding: 12px 0 10px 15px
