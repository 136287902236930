@import '../../../../assets/styles/palette.sass'
@import '../../../../assets/styles/mixins.sass'

.login-form-wrapper
  .sso-wrapper
    text-align: center
    margin-bottom: 23px
  .meta-page-paragraph, .secondary-button
    display: inline

.login-form
  margin-top: 12px
  @include flexbox()
  @include align-items(center)
  @include flex-direction(column)

  .signup-link-container
    @include flexbox()
    @include justify-content(space-between)
    width: 300px

  .text-input-field
    min-height: 70px
  .password-field
    input
      margin-bottom: 23px
  .text-input-field
    .icon-ic-users
      font-size: 16px
      top: 11px
    .icon-ic-lock
      font-size: 24px
      top: 8px
  .remember-me
    margin-bottom: 15px
    font-size: 11px
    letter-spacing: 0.7px
    text-transform: uppercase
    text-decoration: none
    color: $light-blue-800
    label:hover
      cursor: pointer
    input
      vertical-align: bottom
      margin-right: 5px
      &:hover
        cursor: pointer

  .forgot-password
    margin-bottom: 30px
    font-size: 16px
