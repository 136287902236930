@mixin placeholder
  ::-webkit-input-placeholder
    @content
  :-moz-placeholder
    @content
  ::-moz-placeholder
    @content
  :-ms-input-placeholder
    @content

@mixin flexbox()
  display: -webkit-box
  display: -webkit-flex
  display: -moz-box
  display: -moz-flex
  display: -ms-flexbox
  display: flex

@mixin justify-content($value: flex-start)
  @if $value == flex-start
    -webkit-box-pack: start
    -ms-flex-pack: start
  @else if $value == flex-end
    -webkit-box-pack: end
    -ms-flex-pack: end
  @else if $value == space-between
    -webkit-box-pack: justify
    -ms-flex-pack: justify
  @else
    -webkit-box-pack: $value
    -ms-flex-pack: $value

  -webkit-justify-content: $value
  -moz-justify-content: $value
  justify-content: $value

@mixin align-items($value: stretch)
  @if $value == flex-start
    -webkit-box-align: start
    -ms-flex-align: start
  @else if $value == flex-end
    -webkit-box-align: end
    -ms-flex-align: end
  @else
    -webkit-box-align: $value
    -ms-flex-align: $value

  -webkit-align-items: $value
  -moz-align-items: $value
  align-items: $value

@mixin flex-direction($value: row)
  @if $value == row-reverse
    -webkit-box-direction: reverse
    -webkit-box-orient: horizontal
  @else if $value == column
    -webkit-box-direction: normal
    -webkit-box-orient: vertical
  @else if $value == column-reverse
    -webkit-box-direction: reverse
    -webkit-box-orient: vertical
  @else
    -webkit-box-direction: normal
    -webkit-box-orient: horizontal

  -webkit-flex-direction: $value
  -moz-flex-direction: $value
  -ms-flex-direction: $value
  flex-direction: $value

@mixin flex($fg: 1, $fs: null, $fb: null)

  // Set a variable to be used by box-flex properties
  $fg-boxflex: $fg

  // Box-Flex only supports a flex-grow value so let's grab the
  // first item in the list and just return that.
  // rui:
  // more about build-in function length at:
  // http://learnboost.github.io/stylus/docs/bifs.html#lengthexpr
  $fg-boxflex: $fg[0]
  @if length($fg) > 1
    -webkit-box-flex: $fg-boxflex
    -webkit-flex: $fg $fs $fb
    -moz-box-flex: $fg-boxflex
    -moz-flex: $fg $fs $fb
    -ms-flex: $fg $fs $fb
    flex: $fg $fs $fb

font-family Meta-SemiBold
  font-family: Roboto-Regular
  font-weight: bold
