@import '../../../../assets/styles/consts'

.reset-password-success
  .meta-page-image
    width: 50px
    height: 50px
  .major-button
    margin: 35px auto
    width: 228px
  .meta-page-paragraph
    margin-bottom: 44px
  .meta-page-paragraph, .meta-page-sub-title
    @media (max-width: $loginMinDesktopWidth)
      text-align: left
