@import '../../assets/styles/palette.sass'
@import '../../assets/styles/mixins'

.idp-button
  height: 36px
  font-size: 18px
  min-width: 300px
  display: inline-block
  margin: 0 auto
  padding: 0
  font-family: Meta-Regular
  text-align: left
  color: $white
  background-color: $light-blue-800
  border: none
  border-radius: 6px
  white-space: pre-line
  text-decoration: none
  @include align-items(center)
  outline: none
  @include justify-content(center)
  user-select: none
  cursor: pointer

  > div
    position: relative

  .idp-button-icon
    width: 46px
    height: 36px
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    top: 0
    left: 0
    background-color: rgba(0,0,0, .15)
    border-top-left-radius: 6px
    border-bottom-left-radius: 6px
    img
      height: 22px

  .idp-button-text-wrapper
    margin-left: 41px
    padding: 0 10px
    &.short
      margin-left: auto
  .idp-button-text
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    text-align: center
    display: inline-block
    line-height: 36px
    width: 100%

  &:hover
    color: $some-very-pale-grey
  &:active
    color: $dark-grey6
  &:disabled
    cursor: default
    pointer-events: none

