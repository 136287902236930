@import '../../assets/styles/common.sass'
@import '../../assets/styles/palette.sass'

.meta-button
  a, a:hover, a:focus, a:active
    text-decoration: none
    color: inherit
  .meta-button-icon
    font-size: 16px
    margin-right: auto
    display: flex
    align-items: center
    justify-content: center

  &.MuiButton-root
    font-family: Roboto-Medium

  /* Icon Button */
  &.icon-button
    padding: 5px
    min-width: auto

  &.delete-button
    color: $red

  &.MuiButton-outlinedPrimary._hover
    box-shadow: none
    background-color: rgba(2, 119, 189, 0.08)

  &.MuiButtonBase-root:focus
    box-shadow: none

  .loading-spinner-wrapper
    .lds-ring
      .circle
        width: 22px
        height: 22px
        border-color: $white transparent transparent transparent
