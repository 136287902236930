@import '../../palette.sass'

@keyframes ball-beat
  50%
    opacity: 0.2
    transform: scale(0.75)

  100%
    opacity: 1
    transform: scale(1)

.ball-beat
  margin: 10px 0
  > div
    animation-fill-mode: both
    background-color: $meta-link
    width: 13px
    height: 13px
    border-radius: 100%
    margin: 2px
    display: inline-block
    animation: ball-beat 1.3s 0s infinite linear

    &:nth-child(2n-1)
      animation-delay: -0.50s !important


.loading-container.fullscreen
  .ball-beat
    > div
      background-color: white
