@import '../../assets/styles/palette.sass'
@import '../../assets/styles/mixins'
@import '../../assets/styles/consts'

.headless-login-done-container
  .idp-button
    &.medium
      min-width: 200px
      .idp-button-icon
        width: 34px
        padding: 13px 12px 7px
        font-size: 16px
