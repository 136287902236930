// Proofpoint colors
$fuse-dark-blue-5: rgba(0, 0, 0, 0.5)
$fuse-dark-blue-6: rgba(0, 0, 0, 0.6)
$fuse-light-grey-08: rgba(0,0,0,0.08)
$fuse-dark-grey-13: rgba(0,0,0,0.13)
$fuse-dark-blue-24: rgba(0,0,0,0.24)
$fuse-light-grey-38: rgba(0,0,0,0.38)
$fuse-dark-blue-87: rgba(0,0,0,0.87)
$fuse-grey: #DBDBDB
$fuse-ui-grey: #EEEEEE
$fuse-grey-blue: #CFD8DC
$light-blue-500: #619BFF
$light-blue-hover: #70A4FF
$light-blue-pressed: #578BE6
$light-blue-800: #0277BD
$light-blue-900: #01579B
$blue-gray-800: #37474F
$dark-gray: #616161
$light-gray-200: #707070
$blue-gray-900: #263238
$black: #000000
$grey-100: #F5F5F5
$grey-500: #909697
$grey-900: #3A4348
$white: #FFFFFF
$light-green: #E8F5E9
$green: #2E784E
$green-500: #4CAF50
$green-hover: #5EB761
$green-pressed: #367A37
$orange: #A65F05
$orange-500: #FF9800
$orange-hover: #FFA21A
$orange-pressed: #B36A00
$light-orange: #FFF5DB
$light-red: #FEE5EB
$red: #AB052F
$red-500: #EF6A6A
$red-hover: #F07F7F
$red-pressed: #A64E4E
$dark-red: #61021B
$light-greyish-blush: #0777BD
$disabled-black: #737373
$disabled-black2: #666666
$purple-700: #7B1FA2


// Meta old colors - DO NOT USE ANYMORE!
$grey-opac: #fafbfb
$red2: #B71C1C
$light-red2: #fb8787
$light-orange2: #e78f2e
$some-very-pale-grey: #e4e6e8
$lipstick: #e3334f
$grey-opacity8: rgba(38, 38, 38, 0.8)
$degraded-yellow: #fac851
$bright-blue: #47c7f4
$pale-blue-opac: rgba(99, 154, 217, 0.2)
$olive-green: #8E9F3F
$olive-green-opac: rgba(142, 159, 63, 0.2)
$darker-blue-opac: rgba(103, 133, 158, 0.2)
$purple-opac: rgba(148, 116, 191, 0.2)
$light-orange-opac: rgba(231, 152, 91, 0.2)
$meta-link: #5479FF
$visited-meta-link: #2957FB
$meta-disabled-opac: rgba(158,169,200, 0.3)
$field-disabled-background: #F2F2F2
$field-invalid-background: #F6D3DA
$org-picker-background: #3F4B7B
$side-bar-item-active: #76A5F0
$loader-spinner: #52BCF8
$dialog-overlay: #1A2E5B
$darker-darkest: #181E22
$second-darkest: #2C3940
$very-dark-blue555: #282849
$bold-grey: #555555
$brownish-grey: #6b6b6b
$grey: #757575
$darker-blue-grey : #9ea9c8
$cloudy-blue : #afc2cc
$light-gray: #949494
$lighter-grey: #939a9f
$yet-another-light-grey: #C5C9CD
$another-light-grey2: #bfcdd4
$box-shadow-grey: #A8B7C7
$lighter-gray: #B9BDC1
$yet-another-dark-white: #ECF4FD
$light-grey: #e3e6e8
$light-light-gray: #ECEFF1
$background-gray: #E3E6E8
$light-cream-gray: #E5E8EB
$light-bluish-gray: #CFDCE8
$light-grayish: #cccccc
$another-light-grey: #c0c0c0
$blue-grey: #BFD2D7
$lighter-bluish-gray: #D6E0EC
$another-light-light-grey: #c5c5c5
$off-white: #dde4e9
$darker-white: #EFF4FB
$dark-white: #F3F6FB
$darker-light-blue: #DFE8F3
$regular-gray: #EEE
$another-dark-white: #DEE6F0
$another-dark-white2: #EBEFF7
$dashboard-divider-color: #EEF0F7
$black-blue: #172B4D
$darker-bluish-gray: #1D3650
$another-dark-blue: #384E67
$bluish-gray: #566D94
$other-bluish-gray: #6478A0
$title-bluish-gray: #6782A4
$darker-bluish-grey: #7283AC
$greenish-gray: #546E7A
$just-another-bluish-grey: #828AA0
$light-blue-grey: #9db1ca
$azure: #E1f5fE
$another-grey-blue: #6B7BA3
$dark-blue: #006EB0
$dark-blue2: #23649e
$pfpt-blue: #0288D1
$pfpt-light-blue: #e6f6ff
$another-blue: #6095C6
$aqua-blue: #039BE5
$other-light-blue: #00BDFF
$light-blue: #26C6DA
$greenish-blue: #18B1A7
$oval-blue: #5C6BC0
$magenta: #8C0E7C
$watermelon: #D12443
$pink: #b2536a
$warning-orange: #e78747
$dark-yellow: #F99D2F
$dark-orange: #EF7C20
$yellow: #FFBB00
$blue-opac : rgba(50, 118, 178, 0.5)
$light-grey-opac: rgba(0, 0, 0, 0.3)
$stam: #CDDCFC
$another-border-grey: #E0E3EB
$active-background: #ebe9f9
$some-purple-blue: #6163CF
$dark-dark-grey: #262626
$greyish-blue: #7893ab
$background: #4184F3
$dolphin-blue: #a1c4d4
$medium-level: #ffbb00
$purple: #9474bf
$dull-blue: #446f94
$denim: #365977
$silver: #d1d6d4
$booger: #8f9f3f
$dark-dark-blue: #2C2758
$entity-delete-message: #323A4E
$some-white: #fafafa
$some-grey: #e0e0e0
$app-background: rgb(53,56,90)
$disabled-grey: #DDE1EE
$field-border: #CED1DA
$purple-blue: #6D98F7
$disabled-text-input: #C7C7D2
$another-off-white: #E7EAF3
$another-shade-of-white: #EBE9FA
$blue2: #215d90
$another-very-dark-blue: #020D2E
$pale-blue: #639ad9
$blueish-white: #D8DDE9
$turquoise: #18B1A7
$green-opac: rgba(24, 177, 167, 0.2)
$dark-grey6: #9FAAC8
$watermelon-opac: rgba(255, 40, 79, 0.2)
$disabled-text: #777
$invalid-red: #D1462F
$success-green: #2FBF71
$shiny-green: #8DCA35
$valid-blue: #57B8FF
$dashboard-divider: #EEF0F7
$page-background: #F4F6FB
$checkedMenuItemBackground: #D3E0FC
$loginBgColor: #4a81ad
$lightFieldBorder: #DDE1EA
$fieldDisabledBorder: #E3E6E8
$searchHelpText: #b3b9cc
$searchHelpTextTitle: #999
$dropdownArrow: #535B87
$bluishGray2: #495f88
$borderGreyBlue: #D6E0ED
$darkGreyBlue: #1D3650
$bottomDivider: #BEC8CE
$darkBlueOpac: #93afc7
$very-dark-blue: #2c2758
$light-light-blue: #EEF4FC
$activeSearch: #EBEEF0
$metaGrey: #F9FAFC
$lightPink: #d07088
$redButton: #ff4663
$blueButton: #3276b0
$blueLink: #5C96C7
$blueButtonDisabled: #8c9cb0
$yellowWarning: #F99D2F
$fieldErrorLabel: #ff0000
$fieldErrorBorder: #ffb0ac
$fieldLightErrorBorder: #ffc1be
$deleteCardRed: #D9596E
$lightGrey1: #d3d6d8
$lightGrey2: #bfcdd4
$lightGreyBlue: #a1c3d4
$greyOpacity3: rgba(38, 38, 38, 0.3)
$veryLightGrey3: #eaebec
$some-separator-dark-blue: rgba(44, 39, 88, 0.3)
$veryLightGrey4: #f6f8f8
$light-azure: #E8F4FC
$dark-blue3: #3F7798
$light-gray2: #70707045
$lightest-gray: #00000029
$error-red: #EF5350

// Sidebar Colors
$highlight-sidebar: #FFFFFF
$sidebarBackground: #37474F
$secondaryBarColor: #263238
$sidebarTitleHover: #B9BDC1
$sidebarTitleHoverSelected: #FFFFFF
$secondaryBarSelected: #546E7A
$sidebarIconColor: #B9BDC1
$sidebarActiveItem: #00BDFF
$sidebar-disabled-item: #6478A0
