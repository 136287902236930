@import '../../assets/styles/palette.sass'
@import '../../assets/styles/consts'
@import './login-container-mobile'

.background-container
  @media (min-width: $loginMinDesktopWidth)
    height: 100vh
.background-children-container
  @media (min-width: $loginMinDesktopWidth) and (max-height: $minDesktopHeight)
    padding: 20px

.meta-page-sub-title
  color: $second-darkest
  margin-right: auto
  margin-left: auto
  font-size: 28px
  font-family: Meta-Light
  @media (min-width: $loginMinDesktopWidth)
    margin: 0 auto 40px auto

.form-background
  @media (min-width: $loginMinDesktopWidth)
    width: 428px
    margin: auto
    border-radius: 2px

.login-container-loader
  @media (max-width: $loginMinDesktopWidth)
    height: 500px
    background: #fff
    display: flex
    align-items: center
    justify-content: center
    .ball-beat
      text-align: center

.app
  .meta-networks-logo
    display: none
  .meta-page-title
    display: none
  .meta-form-error
    min-height: 30px
    .input-error
      font-size: 14px
    .error-icon img
      width: 18px
      height: 18px
  .login-form
    .sso-section .sso-separator-text
      padding: 15px 0
    .login-form-major-button
      margin: 20px auto 10px
  .verify-pin-form
    .verify-pin-form-major-button
      margin: 20px auto 10px
    .meta-form-error
      margin: 15px auto 0
  .verify-pin-page
    .more-methods
      margin: 15px 0
    .meta-page-paragraph
      margin-bottom: 20px !important

  .major-button
    width: auto
  .background-hexagon
    display: none
  .background-children-container
    text-align: left
    padding: 0
  .meta-form-error
    padding: 8px 0
  .meta-form-success
    width: 100%
    min-width: 100%
  .text-input-field
    min-height: 65px
    input
      margin: 0 auto
      height: 30px
      font-size: 14px
    .input-error
      max-width: 100%
    .error-icon
      img
        width: 18px
    .input-error
      font-size: 14px

  .meta-page-sub-title
    margin-bottom: 10px !important
  .meta-page-paragraph
    margin: auto

  .form-background
    margin: auto
    border-radius: 2px
    min-height: 100%
    padding-top: 15px
    width: 100%

    .form-content
      padding-bottom: 45px
  .login-form-wrapper .sso-wrapper
    margin-bottom: 10px

.windows, .mac, .linux
  .login-wrapper
    .meta-form-error
      width: 36%

.windows
  .login-wrapper
    .form-background
      padding-top: 20px
