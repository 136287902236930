@import '../../assets/styles/palette.sass'
@import '../../assets/styles/mixins'
@import '../../assets/styles/consts'

.set-email-form
  margin-top: 40px
  color: $darker-blue-grey
  text-align: center

  .text-input-field
    margin: 0 auto

  input
    margin: 12px auto 0

  .meta-button
    margin: 48px auto 0

  .have-account-container
    margin: 30px auto 0

  .meta-form-error
    margin: 10px auto 0

.app
  .login-wrapper
    .set-email-form
      .text-input-field
        input
          margin: 30px auto 0
          padding: 10px
