@import '../../palette.sass'

.app
  .app-page-container
    .background-hexagon
      display: none
    .meta-networks-logo
      display: block
      margin: 0 auto 72px
.app-page-container
  .background-container
    height: 100vh
