@import 'palette'
@import './typo'
@import './mixins'
@import '../icons/fonts/Roboto/fonts.css'
@import '../icons/icons.css'
@import './consts'

*
  box-sizing: border-box

body
  padding: 0
  margin: 0
  font-family: 'Meta-Regular', monospace

.form-background
  background-color: $white
  padding-top: 48px
  border: 1px solid #CFDCE8
  box-shadow: 0 0 14px 2px rgba(0,0,0,0.11)
  position: relative
  .form-content
    width: 300px
    display: block
    margin: 0 auto
    padding-bottom: 48px
    @media (max-width: $loginMinDesktopWidth)
      padding-left: 20px
      padding-right: 20px
    h1
      font-size: 22px
      letter-spacing: 0
      font-family: Meta-Regular
      color: $very-dark-blue
      text-align: center
      font-weight: normal

    p
      margin: 50px auto
      font-family: Meta-Regular
      color: $darker-blue-grey
      text-align: center
      max-width: 380px
      font-size: 16px

.hidden
  visibility: hidden

/* hide input shadow for ios */
input
  background-clip: padding-box

/* Hide HTML5 Up and Down arrows. */
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

input[type="number"]
  -moz-appearance: textfield

.meta-button
  .button-container
    width: 158px
    margin: 0 auto
    .label
      font-size: 14px

  &:disabled
    color: $another-shade-of-white

.loader-wrapper
  text-align: center
