@import '../../assets/styles/palette.sass'
@import '../../assets/styles/mixins'
@import '../../assets/styles/consts'

.enter-pin-page
  .form-background .form-content
    width: 330px
    .meta-page-paragraph
      text-align: center
    .enter-pin-form
      margin-top: 20px
      color: $darker-blue-grey
      font-family: Meta-Regular
      display: flex
      align-items: center
      flex-direction: column

      .code-input-container
        position: relative

      .ios-code-field
        margin-top: 30px
        min-height: auto

        input
          font-size: 16px !important
          text-indent: 45px

      .react-code-input
        input
          &:focus
            outline: none

          &:nth-child(4)
            @media (min-width: 460px)
              margin-left: 25px !important

      .digits-separator
        @media (max-width: 460px)
          display: none

        position: absolute
        top: 0
        left: 149px
        font-size: 36px

      .form-title
        margin-top: 20px

      .form-footer
        margin-top: 48px

      .meta-form-error
        margin-top: 23px

      .resend-code
        margin-top: 10px

        &.clicked
          color: $light-grayish
          cursor: inherit

      .go-back-container span
        color: $darker-blue-grey

      .bottom-link-container
        margin-top: 15px
        .meta-page-paragraph
          color: $light-grayish

.app .login-wrapper .ios-code-field.text-input-field
  min-height: auto
