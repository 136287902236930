@import '../../../../assets/styles/palette.sass'
@import '../../../../assets/styles/mixins.sass'
@import '../../../../assets/styles/consts'

.login-no-org-form
  text-align: center
  font-family: Meta-Regular
  margin-top: 20px

  .input-group
    max-width: 400px
    margin: 0 auto
    display: table
    position: relative
    border-collapse: separate
    text-align: center
    font-family: Roboto, helvetica, arial, sans-serif
    font-weight: 300
    font-size: 16px
    line-height: 1.42857143
    color: #333

    @media (max-width: $loginMinDesktopWidth)
      max-width: 100%

    .org-input
      font-family: Meta-Regular
      width: 100%
      height: 40px
      padding: 6px 12px
      margin: 0
      box-shadow: none
      outline: none
      display: table-cell
      position: relative
      z-index: 2
      float: left
      font-size: 14px
      line-height: 1.42857143
      color: $bold-grey
      background-color: $white
      background-image: none
      border: 1px solid $another-light-light-grey
      border-right: none
      border-radius: 0
      text-align: left

    .org-suffix
      padding: 6px 7px
      font-size: 14px
      font-family: Meta-Regular
      font-weight: normal
      line-height: 1
      color: #555
      text-align: center
      width: 1%
      white-space: nowrap
      vertical-align: middle
      display: table-cell
      box-sizing: border-box
      border-collapse: separate
      border: 1px solid $another-light-light-grey
      border-left: none

  .secondary-button
    display: inline

  .meta-button
    margin: 60px 0 10px

  .meta-form-error
    @media (max-width: $loginMinDesktopWidth)
      width: 100%

  .bottom-link-container
    margin-top: 0

.app
  .login-wrapper
    .login-no-org-form
      .text-input-field
        input
          margin: 0
