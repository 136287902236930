@import '../../assets/styles/palette.sass'

.app
  .loading-page-container
    text-align: center
    .background-hexagon
      display: none
    .meta-networks-logo
      display: block
      margin: 0 auto 72px
.loading-page-container
  .background-container
    height: 100vh
  .ball-beat
    div
      background: $white
