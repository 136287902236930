@import '../../assets/styles/palette.sass'
@import '../../assets/styles/mixins'

.meta-page-sub-title
  font-size: 18px
  letter-spacing: 0
  font-family: Meta-Regular
  color: $very-dark-blue
  text-align: center
  margin-bottom: 20px

.meta-page-paragraph
  font-family: Meta-Regular
  text-align: left
  max-width: 350px
  font-size: 14px
  color: $bluish-gray
