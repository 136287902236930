@font-face {
  font-family: Roboto-Regular;
  src: local("Roboto-Regular"), url(./Roboto-Regular.ttf);
}

@font-face {
  font-family: Roboto-Bold;
  src: local("Roboto-Bold"), url(./Roboto-Bold.ttf);
}

@font-face {
  font-family: Roboto-Medium;
  src: local("Roboto-Medium"), url(./Roboto-Medium.ttf);
}

@font-face {
  font-family: Roboto-Light;
  src: local("Roboto-Light"), url(./Roboto-Light.ttf);
}

@font-face {
  font-family: Roboto-Thin;
  src: local("Roboto-Thin"), url(./Roboto-Thin.ttf);
}

@font-face {
  font-family: Roboto-Black;
  src: local("Roboto-Black"), url(./Roboto-Black.ttf);
}
