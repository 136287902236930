@import '../../assets/styles/palette.sass'
@import '../../assets/styles/mixins'
@import '../../assets/styles/consts'

.zone-login-form
  text-align: center
  font-family: Meta-Regular

  .input-group
    max-width: 400px
    margin: 0 auto
    display: table
    position: relative
    border-collapse: separate
    text-align: left
    font-family: Roboto, helvetica, arial, sans-serif
    font-weight: 300
    font-size: 16px
    line-height: 1.42857143
    color: #333

    @media (max-width: $loginMinDesktopWidth)
      max-width: 100%

    input
      font-family: Meta-Regular
      width: 100%
      height: 40px
      padding: 6px 12px
      margin: 10px 0
      box-shadow: none
      outline: none
      display: table-cell
      position: relative
      z-index: 2
      float: left
      font-size: 14px
      line-height: 1.42857143
      color: #555
      background-color: #fff
      background-image: none
      border: 1px solid $another-light-light-grey
      border-radius: 0

      @media (max-width: $loginMinDesktopWidth)
        font-size: 3.5vw
        &::-webkit-input-placeholder /* Chrome/Opera/Safari */
          font-size: 3.5vw
        &::-moz-placeholder /* Firefox: 19+ */
          font-size: 3.5vw
        &:-ms-input-placeholder /* IE: 10+ */
          font-size: 3.5vw
        &:-moz-placeholder /* Firefox: 18- */
          font-size: 3.5vw


    select
      margin: 10px 0
      width: 100%
      height: 40px
      font-size: 14px
      text-indent: 10px
      background-color: white
    span
      padding: 6px 12px
      font-size: 14px
      font-family: Meta-Regular
      font-weight: normal
      line-height: 1
      color: #555
      text-align: center
      background-color: #eee
      border: 1px solid $another-light-light-grey
      border-left: none
      width: 1%
      white-space: nowrap
      vertical-align: middle
      display: table-cell
      box-sizing: border-box
      border-collapse: separate

      @media (max-width: $loginMinDesktopWidth)
        font-size: 3.5vw

  .secondary-button
    display: inline

  .paragraph-container
    margin: 60px auto 0
    .meta-page-paragraph
      display: inline
      white-space: pre-line

  .meta-form-error
    @media (max-width: $loginMinDesktopWidth)
      width: 100%

  .meta-button
    margin-top: 40px
.app
  .login-wrapper
    .login-no-org-form
      .text-input-field
        input
          margin: 0
