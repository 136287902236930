@font-face {
    font-family: "icons";
    src: url("./icons.eot?d3134b8ac94d9bf2a4a095111c80eebc#iefix") format("embedded-opentype"),
url("./icons.woff2?d3134b8ac94d9bf2a4a095111c80eebc") format("woff2"),
url("./icons.woff?d3134b8ac94d9bf2a4a095111c80eebc") format("woff");
}

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-action-menu:before {
    content: "\f101";
}
.icon-active:before {
    content: "\f102";
}
.icon-add:before {
    content: "\f103";
}
.icon-alert:before {
    content: "\f104";
}
.icon-api-cloud:before {
    content: "\f105";
}
.icon-api-key:before {
    content: "\f106";
}
.icon-app:before {
    content: "\f107";
}
.icon-apps-admin:before {
    content: "\f108";
}
.icon-apps-metaconnect:before {
    content: "\f109";
}
.icon-apps-ssp:before {
    content: "\f10a";
}
.icon-apps:before {
    content: "\f10b";
}
.icon-back:before {
    content: "\f10c";
}
.icon-cancel:before {
    content: "\f10d";
}
.icon-center:before {
    content: "\f10e";
}
.icon-check:before {
    content: "\f10f";
}
.icon-chevron-down:before {
    content: "\f110";
}
.icon-chevron:before {
    content: "\f111";
}
.icon-clone:before {
    content: "\f112";
}
.icon-close:before {
    content: "\f113";
}
.icon-cloud-app:before {
    content: "\f114";
}
.icon-combined-shape:before {
    content: "\f115";
}
.icon-community:before {
    content: "\f116";
}
.icon-connected:before {
    content: "\f117";
}
.icon-copy-id:before {
    content: "\f118";
}
.icon-copy:before {
    content: "\f119";
}
.icon-cross:before {
    content: "\f11a";
}
.icon-dashboard-protected-users:before {
    content: "\f11b";
}
.icon-dashboard-violations:before {
    content: "\f11c";
}
.icon-date:before {
    content: "\f11d";
}
.icon-delete-fuse:before {
    content: "\f11e";
}
.icon-delete:before {
    content: "\f11f";
}
.icon-device-mgmt:before {
    content: "\f120";
}
.icon-device:before {
    content: "\f121";
}
.icon-download:before {
    content: "\f122";
}
.icon-dropdown:before {
    content: "\f123";
}
.icon-easylink:before {
    content: "\f124";
}
.icon-edit:before {
    content: "\f125";
}
.icon-egress-rule:before {
    content: "\f126";
}
.icon-elements:before {
    content: "\f127";
}
.icon-email:before {
    content: "\f128";
}
.icon-expand-all-closed:before {
    content: "\f129";
}
.icon-expand-all-collapsed:before {
    content: "\f12a";
}
.icon-expand-all:before {
    content: "\f12b";
}
.icon-external-link:before {
    content: "\f12c";
}
.icon-filter:before {
    content: "\f12d";
}
.icon-first-page:before {
    content: "\f12e";
}
.icon-fuse-download:before {
    content: "\f12f";
}
.icon-graph:before {
    content: "\f130";
}
.icon-group-29:before {
    content: "\f131";
}
.icon-group-9:before {
    content: "\f132";
}
.icon-groups:before {
    content: "\f133";
}
.icon-help:before {
    content: "\f134";
}
.icon-hide-password:before {
    content: "\f135";
}
.icon-ic-add:before {
    content: "\f136";
}
.icon-ic-admin-sidebar:before {
    content: "\f137";
}
.icon-ic-admin:before {
    content: "\f138";
}
.icon-ic-am-pm-picker:before {
    content: "\f139";
}
.icon-ic-android:before {
    content: "\f13a";
}
.icon-ic-announce:before {
    content: "\f13b";
}
.icon-ic-apple:before {
    content: "\f13c";
}
.icon-ic-back:before {
    content: "\f13d";
}
.icon-ic-block:before {
    content: "\f13e";
}
.icon-ic-category:before {
    content: "\f13f";
}
.icon-ic-certificate:before {
    content: "\f140";
}
.icon-ic-chromeos:before {
    content: "\f141";
}
.icon-ic-close-wizard:before {
    content: "\f142";
}
.icon-ic-community:before {
    content: "\f143";
}
.icon-ic-dashboard-sidebar:before {
    content: "\f144";
}
.icon-ic-dashboard:before {
    content: "\f145";
}
.icon-ic-down-chevron:before {
    content: "\f146";
}
.icon-ic-draw-closed:before {
    content: "\f147";
}
.icon-ic-error:before {
    content: "\f148";
}
.icon-ic-filled-right-arrow:before {
    content: "\f149";
}
.icon-ic-hide:before {
    content: "\f14a";
}
.icon-ic-insights-sidebar:before {
    content: "\f14b";
}
.icon-ic-ios:before {
    content: "\f14c";
}
.icon-ic-left-arrow:before {
    content: "\f14d";
}
.icon-ic-line:before {
    content: "\f14e";
}
.icon-ic-linux:before {
    content: "\f14f";
}
.icon-ic-lock:before {
    content: "\f150";
}
.icon-ic-minus:before {
    content: "\f151";
}
.icon-ic-network-element:before {
    content: "\f152";
}
.icon-ic-network-elements-sidebar:before {
    content: "\f153";
}
.icon-ic-network-sidebar:before {
    content: "\f154";
}
.icon-ic-network:before {
    content: "\f155";
}
.icon-ic-notification:before {
    content: "\f156";
}
.icon-ic-notifications-none:before {
    content: "\f157";
}
.icon-ic-notifications-outline:before {
    content: "\f158";
}
.icon-ic-organizations-sidebar:before {
    content: "\f159";
}
.icon-ic-pd-bg:before {
    content: "\f15a";
}
.icon-ic-pd:before {
    content: "\f15b";
}
.icon-ic-phone:before {
    content: "\f15c";
}
.icon-ic-report:before {
    content: "\f15d";
}
.icon-ic-right-arrow:before {
    content: "\f15e";
}
.icon-ic-search-empty:before {
    content: "\f15f";
}
.icon-ic-search-x:before {
    content: "\f160";
}
.icon-ic-send:before {
    content: "\f161";
}
.icon-ic-server:before {
    content: "\f162";
}
.icon-ic-show:before {
    content: "\f163";
}
.icon-ic-slack:before {
    content: "\f164";
}
.icon-ic-software-item:before {
    content: "\f165";
}
.icon-ic-status-pending:before {
    content: "\f166";
}
.icon-ic-status-unknown:before {
    content: "\f167";
}
.icon-ic-status-warning:before {
    content: "\f168";
}
.icon-ic-support:before {
    content: "\f169";
}
.icon-ic-sync:before {
    content: "\f16a";
}
.icon-ic-topology:before {
    content: "\f16b";
}
.icon-ic-type:before {
    content: "\f16c";
}
.icon-ic-up-chevron:before {
    content: "\f16d";
}
.icon-ic-users-sidebar:before {
    content: "\f16e";
}
.icon-ic-users:before {
    content: "\f16f";
}
.icon-ic-version-control:before {
    content: "\f170";
}
.icon-ic-webhook:before {
    content: "\f171";
}
.icon-ic-windows:before {
    content: "\f172";
}
.icon-identity-provider:before {
    content: "\f173";
}
.icon-insert-link:before {
    content: "\f174";
}
.icon-last-page:before {
    content: "\f175";
}
.icon-ldap:before {
    content: "\f176";
}
.icon-list:before {
    content: "\f177";
}
.icon-lock-item:before {
    content: "\f178";
}
.icon-log-streaming:before {
    content: "\f179";
}
.icon-macos:before {
    content: "\f17a";
}
.icon-mapped-service:before {
    content: "\f17b";
}
.icon-mapped-subnet:before {
    content: "\f17c";
}
.icon-max:before {
    content: "\f17d";
}
.icon-menu-admin:before {
    content: "\f17e";
}
.icon-menu-back:before {
    content: "\f17f";
}
.icon-menu-devices-managment:before {
    content: "\f180";
}
.icon-menu-elements:before {
    content: "\f181";
}
.icon-menu-group:before {
    content: "\f182";
}
.icon-menu-logs:before {
    content: "\f183";
}
.icon-menu-max:before {
    content: "\f184";
}
.icon-menu-min:before {
    content: "\f185";
}
.icon-menu-networks:before {
    content: "\f186";
}
.icon-menu-notification:before {
    content: "\f187";
}
.icon-menu-overview:before {
    content: "\f188";
}
.icon-meta-logo:before {
    content: "\f189";
}
.icon-metaconnect:before {
    content: "\f18a";
}
.icon-metaports-cluster:before {
    content: "\f18b";
}
.icon-metaports-failover:before {
    content: "\f18c";
}
.icon-metaports:before {
    content: "\f18d";
}
.icon-mobile:before {
    content: "\f18e";
}
.icon-native-service:before {
    content: "\f18f";
}
.icon-org-home:before {
    content: "\f190";
}
.icon-organizations:before {
    content: "\f191";
}
.icon-orgs-tree-root:before {
    content: "\f192";
}
.icon-pause:before {
    content: "\f193";
}
.icon-peering:before {
    content: "\f194";
}
.icon-pencil:before {
    content: "\f195";
}
.icon-play:before {
    content: "\f196";
}
.icon-policy-summary:before {
    content: "\f197";
}
.icon-policy:before {
    content: "\f198";
}
.icon-proofpoint-logo-white:before {
    content: "\f199";
}
.icon-proofpoint-logo:before {
    content: "\f19a";
}
.icon-protocol-groups:before {
    content: "\f19b";
}
.icon-refresh:before {
    content: "\f19c";
}
.icon-remove:before {
    content: "\f19d";
}
.icon-role:before {
    content: "\f19e";
}
.icon-routing-group:before {
    content: "\f19f";
}
.icon-search-error:before {
    content: "\f1a0";
}
.icon-search-invalid:before {
    content: "\f1a1";
}
.icon-search-off:before {
    content: "\f1a2";
}
.icon-search:before {
    content: "\f1a3";
}
.icon-server:before {
    content: "\f1a4";
}
.icon-settings:before {
    content: "\f1a5";
}
.icon-show-password:before {
    content: "\f1a6";
}
.icon-sms:before {
    content: "\f1a7";
}
.icon-ssl-certificate:before {
    content: "\f1a8";
}
.icon-star-empty:before {
    content: "\f1a9";
}
.icon-star-fill:before {
    content: "\f1aa";
}
.icon-stop:before {
    content: "\f1ab";
}
.icon-stopwatch:before {
    content: "\f1ac";
}
.icon-swg-content:before {
    content: "\f1ad";
}
.icon-swg-menu-protection:before {
    content: "\f1ae";
}
.icon-swg-ports:before {
    content: "\f1af";
}
.icon-swg-threat:before {
    content: "\f1b0";
}
.icon-switch:before {
    content: "\f1b1";
}
.icon-system-shortcut:before {
    content: "\f1b2";
}
.icon-table-search:before {
    content: "\f1b3";
}
.icon-table:before {
    content: "\f1b4";
}
.icon-thumb:before {
    content: "\f1b5";
}
.icon-tip:before {
    content: "\f1b6";
}
.icon-tooltip:before {
    content: "\f1b7";
}
.icon-topbar-arrow-dropdown:before {
    content: "\f1b8";
}
.icon-topbar-updates:before {
    content: "\f1b9";
}
.icon-topology:before {
    content: "\f1ba";
}
.icon-troubleshooting:before {
    content: "\f1bb";
}
.icon-trusted-networks:before {
    content: "\f1bc";
}
.icon-undo:before {
    content: "\f1bd";
}
.icon-url-filters:before {
    content: "\f1be";
}
.icon-user:before {
    content: "\f1bf";
}
.icon-web-isolation:before {
    content: "\f1c0";
}
.icon-win:before {
    content: "\f1c1";
}
