@import 'palette'

.MuiIconButton-label
  font-size: 14px
.unselectable
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

.focus-none
  &:focus
    outline: none

.position-relative
  position: relative

.ellipsis
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap

.country-helper-option
  display: flex
  .icon
    padding: 0 5px

$header-height: 56px
$filter-bar-height: 56px
$page-min-width: 450px

//new entity colors
.entity-color-network
  color: $oval-blue
  .chip
    color: $white
    background-color: $oval-blue

.entity-color-resource
  color: $warning-orange
  .chip
    color: $white
    background-color: $warning-orange

.entity-color-user-group
  color: $other-light-blue
  .chip
    color: $white
    background-color: $other-light-blue

.entity-color-notification
  color: $green-500
  .chip
    color: $white
    background-color: $green-500

.entity-color-log
  color: $pink
  .chip
    color: $white
    background-color: $pink

.entity-color-web-security
  color: $magenta
  .chip
    color: $white
    background-color: $magenta

.entity-color-device-mangment
  color: #01579B
  .chip
    color: $white
    background-color: #01579B

.entity-color-admin
  color: $greenish-blue
  .chip
    color: $white
    background-color: $greenish-blue

.entity-color-organization
  color: $light-blue
  .chip
    color: $white
    background-color: $light-blue

.entity-color-system-shortcut
  color: $greenish-gray
  .chip
    color: $white
    background-color: $greenish-gray

.chip-color-error
  color: $red2
  .chip
    color: $white
    background-color: $red2

.chip-color-success
  color: $green-500
  .chip
    color: $white
    background-color: $green-500

.chip-color-property
  color: $light-blue-800
  .chip
    color: $white
    background-color: $light-blue-800

.entity-color-connected
  color: $blue-gray-900
  .chip
    color: $white
    background-color: $blue-gray-900
