@import '../../assets/styles/palette.sass'
@import '../../assets/styles/mixins'

.text-input-field
  position: relative
  min-height: 75px
  text-align: left
  width: 300px
  label
    font-family: Meta-Regular
    font-size: 14px
    color: $bluish-gray
  .icon
    position: absolute
    top: 12px
    left: 13px
    font-size: 12px
    color: $light-blue-800
  input
    width: 300px
    height: 32px
    outline: none
    color: $very-dark-blue
    text-indent: 7px
    font-size: 14px
    letter-spacing: 1.1px
    border: 1px solid $lighter-gray
    border-radius: 2px
    font-family: Meta-Regular
    +placeholder()
      color: $grey
      font-size: 13px
      letter-spacing: 0
  input:-webkit-autofill
      -webkit-text-fill-color: $light-blue-800 !important
      -webkit-box-shadow: 0 0 0 30px $white inset
  input:focus + .input-error
    visibility: hidden

  &.error
    input
      margin: 0
      padding-right: 35px
      border: 1px solid $red2

  .input-error
    display: inline-block
    max-width: 100%
    font-size: 13px
    color: $red2
    font-family: Meta-Regular
    margin: 3px 0 0

