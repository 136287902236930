@import '../../../../assets/styles/palette.sass'
@import '../../../../assets/styles/mixins.sass'

.verify-pin-form
  @include flexbox()
  @include align-items(center)
  @include flex-direction(column)
  .password-field
    min-height: 0
  .text-input-field
    .icon-ic-lock
      pointer-events: none
      font-size: 24px
      top: 8px
    input
      margin-bottom: 10px
  .meta-form-error
    margin: 45px auto 0

  .resend-code
    margin-bottom: 30px
    text-align: right
    > span
      color: $white
      font-size: 11px
      letter-spacing: 0.7px
      text-align: right
      text-transform: uppercase
      text-decoration: none
      &:hover
        color: $lightPink
        cursor: pointer
      &:active
        color: $pink
        cursor: pointer
