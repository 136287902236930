@import '../../assets/styles/palette'

.loading-spinner-wrapper
  height: 100%
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  opacity: 0.5
  .lds-ring
    display: inline-block
    position: relative
    width: 33px
    height: 33px
    .circle
      box-sizing: border-box
      display: block
      position: absolute
      margin: 6px
      border-radius: 50%
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
      width: 28px
      height: 28px
      border: 3px solid $loader-spinner
      border-color: $loader-spinner transparent transparent transparent

      &:nth-child(1)
        animation-delay: -0.45s
      &:nth-child(2)
        animation-delay: -0.3s
      &:nth-child(3)
        animation-delay: -0.15s

@keyframes lds-ring
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)
