@import "../../palette.sass"

.text-hidden
  display: none
.App
  width: 100vw
  height: 100vh
  background: $backgroundLightBlue linear-gradient(207deg, $backgroundLightBlue 0%, $backgroundDarkBlue 100%)
  .page-inner-wrapper
    height: 100vh
    .logo-and-page-wrapper
      padding-top: 10vh
